<template>
    <div class="container mt-5">
        <div class="row">
            <div class="col-sm-12 col-md-7 col-lg-6 col-xl-5 mx-auto">
                <div class="card glass p-3">

                    <div class="card-body">

                        <form @submit.prevent="Submit" class="auth">

                            <input :checked="tabSelect === 'signin'" id='signin' name='action' type='radio' value='signin'  @change="onChange($event)">
                            <label for='signin'>Sign in</label>
                            <input :checked="tabSelect === 'signup'" id='signup' name='action' type='radio' value='signup'  @change="onChange($event)">
                            <label for='signup'>Sign up</label>
                            <input :checked="tabSelect === 'reset'" id='reset' name='action' type='radio' value='reset'  @change="onChange($event)">
                            <label for='reset'>Reset</label>
                            <div id='wrapper'>
                                <div id='arrow'></div>

                                <input  v-model="email" placeholder='Email' type='email'>
                                <input  v-model="password" placeholder='Password' type='password'>
                                <input  v-model="passwordConfirm" :rules="[comparePasswords]" placeholder='Repeat password' type='password'>
                            </div>
                            <div class="alert alert-danger animated pulse" role="alert" v-if="alert">
                                {{ getError }}
                            </div>
                            <button type='submit' class="colorxblack hoverx" :disabled="getLoading">
                                <span>
                                  Reset password
                                  <br>
                                  Sign in
                                  <br>
                                  Sign up
                                </span>
                            </button>

                            <button type="button" class="colorxfb hoverx" :disabled="getLoading" @click="userSignInWithFB"><i class="fab fa-facebook-square"></i> Sign in with Facebook </button>
                            <button type="button" class="colorxgithub hoverx" :disabled="getLoading" @click="userSignInWithGitHub"><i class="fab fa-github"></i> Sign in with Github </button>
                            <button type="button" class="colorxgg hoverx" :disabled="getLoading" @click="userSignInWithGoogle"><i class="fab fa-google"></i> Sign in with Google </button>
                            <button type="button" class="colorxms hoverx" :disabled="getLoading" @click="userSignInWithMicrosoft"><i class="fab fa-microsoft"></i> Sign in with Microsoft</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import 'animate.css/source/_base.css'
import 'animate.css/source/_vars.css'
import 'animate.css/source/attention_seekers/pulse.css'
import router from "@/router";
import { mapActions,mapGetters } from 'vuex'

export default {
    props: ["action"],
    data() {
        return {
            tabSelect:'',
            email: '',
            password: '',
            passwordConfirm: '',
            alert: false
        }
    },
    async beforeMount() {
        if (this.action === 'signout') {
            await this.userSignOut()

            if (router.currentRoute.path !== '/auth/signin') {
                await router.push('/auth/signin')
            }
        }else{
            this.tabSelect = this.action

        }

    },
    beforeRouteUpdate(to,form,next){
        this.tabSelect = to.params.action
        next()

    },
    methods: {
        ...mapActions([
                'userSignIn',
                'userSignUp',
                'userReset',
                'setError',
                'userSignInWithFB',
                'userSignInWithGitHub',
                'userSignInWithGoogle',
                'userSignInWithMicrosoft',
                'userSignOut'
            ]
        ),
        async Submit () {
            switch (this.tabSelect) {
                case "signin":
                    await this.userSignIn({email: this.email, password: this.password})
                    break
                case "signup":
                    if (this.comparePasswords !== true) {
                        this.setError(this.comparePasswords)
                        return
                    }
                    await this.userSignUp({email: this.email, password: this.password})
                    break
                case "reset":
                    await this.userReset({email:this.email})
                    await this.$swal.fire(
                        'Success!',
                        'send reset link to '+this.email+', check your inbox!',
                        'success'
                    )
                    await router.push('/auth/signin')

                    break
            }
        },
        onChange(event) {
            if (event.target.value !== this.tabSelect) router.push('/auth/'+event.target.value);
        }
    },
    computed:{
        ...mapGetters([
            'getError',
            'getLoading',
        ]),
        comparePasswords () {
            return this.password === this.passwordConfirm ? true :
                'Password and confirm password doesn\'t match'
        }

    },
    watch: {
        getError (value) {
            this.alert = !!value;
        },
        alert (value) {
            if (!value) this.setError( null);
        }
    }
}
</script>
<style scoped lang="css">
@import "../assets/css/auth.css";
* {
    box-sizing: initial;
}
</style>